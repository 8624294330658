import logo from './logo.svg';
import './App.css';
import ExcelProcessor from './components/ExcelProcessor';


function App() {
  return (
    <div className="App">
      <header className="">
        <h1>Excel File Processor</h1>
      </header>
      <main>
        <ExcelProcessor />
      </main>
    </div>
  );
}

export default App;
