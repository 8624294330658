import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import FileUpload from './FileUpload';

const ExcelProcessor = () => {
  const [processedFile, setProcessedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sortByLength = (arr) => {
    return arr.sort((a, b) => b.length - a.length);
  };

  const processExcelFile = async (file) => {
    try {
      setLoading(true);
      setError(null);

      const reader = new FileReader();
      
      reader.onload = (e) => {
 
           
         
        // const data = new Uint8Array(e.target.result);
        // const workbook = XLSX.read(data, { type: 'array' });
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        // Debug: Log available sheet names
        console.log('Available sheets:', workbook.SheetNames);
          
        // Check if 'name' sheet exists
       
        const sheetName = workbook.SheetNames.includes('name') ? 'name' : workbook.SheetNames[1];
        const worksheet2 = workbook.Sheets[sheetName];        
        if (!worksheet2 || !worksheet2['!ref']) {
          setError("Invalid sheet structure");
          setLoading(false);
          return;
        }
        if (!worksheet2) {
          setError("Sheet named 'name' not found in the workbook");
          setLoading(false);
          return;
        }
        
        // Check if ref exists
        if (!worksheet2['!ref']) {
          setError("No data range found in the 'name' sheet");
          setLoading(false);
          return;
        }

        // Get Sheet2 (name sheet) data
        // const worksheet2 = workbook.Sheets['name'];
        const commonPhrases = [];

        const range2 = XLSX.utils.decode_range(worksheet2['!ref']);
        for (let row2 = 1; row2 <= range2.e.r; row2++) { // Changed range2.s.r to 1
        const cellAddress2 = XLSX.utils.encode_cell({ r: row2, c: 0 });
        const cell2 = worksheet2[cellAddress2];
        if (cell2) {
          commonPhrases.push(cell2.v);
        }
        }

        const sortedPhrases = sortByLength(commonPhrases);

        // Process Sheet1
        const sheetName1 = workbook.SheetNames.includes('Sheet1') ? 'Sheet1' : workbook.SheetNames[0];
        const worksheet1 = workbook.Sheets[sheetName1];
        const range = XLSX.utils.decode_range(worksheet1['!ref']);
        const updatedRecords = [];

        for (let row = range.s.r; row <= range.e.r; row++) {
          const wCellAddress = XLSX.utils.encode_cell({ r: row, c: 22 });
          const wCell = worksheet1[wCellAddress];
          const wValue = wCell ? wCell.v : undefined;

          if (wValue) {
            for (let removePhrase of sortedPhrases) {
              if (wValue.includes(removePhrase)) {
                removePhrase = removePhrase.trim();
                const updatedValue = wValue.replace(removePhrase, "").trim();

                updatedRecords.push({
                  'w': wValue,
                  'x': removePhrase,
                  'y': updatedValue,
                });

                // Update X Column
                const xCellAddress = XLSX.utils.encode_cell({ r: row, c: 23 });
                worksheet1[xCellAddress] = { t: 's', v: removePhrase };

                // Update Y Column
                const yCellAddress = XLSX.utils.encode_cell({ r: row, c: 24 });
                worksheet1[yCellAddress] = { t: 's', v: updatedValue };
                break;
              }
            }
          }
        }

        // Write the modified workbook
        const newWorkbook = XLSX.write(workbook, { 
          bookType: 'xlsx', 
          type: 'binary',
          compression: true,
          bookSST: true
        });
  
        const blob = new Blob([s2ab(newWorkbook)], { 
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });
        setProcessedFile(blob);
        setLoading(false);
        // const newWorkbook = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // const blob = new Blob([newWorkbook], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // setProcessedFile(blob);
        // setLoading(false);
      };

      // reader.readAsArrayBuffer(file);
      reader.readAsBinaryString(file); // Instead of readAsArrayBuffer

    } catch (err) {
      setError('Error processing file: ' + err.message);
      setLoading(false);
    }
  };
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  const downloadFile = () => {
    if (processedFile) {
      const url = window.URL.createObjectURL(processedFile);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'processed_excel.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  return (
    <div className="excel-processor">
      <FileUpload onFileSelect={processExcelFile} />
      
      {loading && <div>Processing file...</div>}
      {error && <div className="error">{error}</div>}
      
      {processedFile && (
        <button onClick={downloadFile}>
          Download Processed File
        </button>
      )}
    </div>
  );
};

export default ExcelProcessor;